import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import Header from './components/Header';
import Cart from './components/Cart';
import { CartProvider } from './contexts/CartContext';
import './App.css';
import Checkout from './components/Checkout';
import OrderConfirmation from './components/OrderConfirmation';
import Register from './components/Register';
import Login from './components/Login';

function App() {
    return (
        <CartProvider>
            <Router>
                <div className="App">
                    <header className="App-header">
                        <Header /> {/* Include the Header component */}
                    </header>
                    <main>
                        <Routes>
                            <Route path="/product-list" element={<ProductList />} />
                            <Route path="/product/:id" element={<ProductDetail />} />
                            <Route path="/cart" element={<Cart />} /> {/* Fix here */}
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/order-confirmation" element={<OrderConfirmation />} />
                            <Route path="/register" element={<Register />} /> {/* Fix here */}
                            <Route path="/login" element={<Login />} /> {/* Fix here */}
                        </Routes>
                    </main>
                </div>
            </Router>
        </CartProvider>
    );
}

export default App;
